import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import AppleSignin from 'react-apple-signin-auth';
import jwt_decode from 'jwt-decode';

import { feedbackEnrollment } from '../../store/actions';
import Layout from '../../hoc/Layout/Layout';
import Button from '../../components/UI/button/button';
import keys from '../../config/keys';
import './Enrollment.scss';

let firstAccess = false;

const Enrollment = (props) => {
  if (firstAccess) {
    window.scrollTo(0, 0);
  }
  const redirectURIApple = window.location.origin + '/auth';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(props.error || null);
  const isValid = (email !== '' && password != null && password.length >= 8);

  const submitHandler = () => {
    setError(null);
    const receiverId = props.receiverId;
    props.onFeedbackEnrollment({ email, password, receiverId });
  }

  const handleKeyDownCloseKeyboard = (event) => {
    if (event.key === 'Enter') {
      document.activeElement.blur();
    }
  }

  useEffect(() => setError(props.error), [props.error]);

  return (
    <Layout>
      <div className='enrollment-container'>
        <div className='slideBodyEnrollment'>
          <div className='content'>
            <h2 className='web'>
              Super !
              <br/>
              Dernière étape pour envoyer ta demande
            </h2>
            <p className='description'>
              Crée un compte en 5 secondes pour récupérer tes feedbacks.
            </p>

            <form className='enrollmentForm' onSubmit={() => submitHandler()}>
              <p>Saisis ton identifiant</p>
              <input type='text' onChange={e => setEmail(e.target.value)} value={email} placeholder='elon@mail.com'
                     onKeyDown={handleKeyDownCloseKeyboard}/>
              <p>Saisis ton mot de passe</p>
              <input type='password' onChange={e => setPassword(e.target.value)} value={password} placeholder='Mot de passe'
                     onKeyDown={handleKeyDownCloseKeyboard}/>
            </form>
            {error && (
              <p className='small enrollmentError'>{error}</p>
            )}

            <div className='btnContainer'>
              <Button btnType={isValid ? '' : 'disabled'} clicked={() => submitHandler()}>
                Valider et envoyer
              </Button>
            </div>

            <div className='separator'>OU</div>

            <div className='socialButtons'>
              <GoogleLogin
                clientId={keys.googleClientId}
                buttonText="Continuer avec Google"
                onSuccess={(response) => {
                  const email = response.profileObj.email;
                  const receiverId = props.receiverId;
                  props.onFeedbackEnrollment({ email, undefined, receiverId });
                }}
                onFailure={(error) => {
                  console.log('Google failure');
                  console.log(error);
                }}
                cookiePolicy={'single_host_origin'}
                className='googleBtn' />
                  
              <AppleSignin
                authOptions={{
                  clientId: (String)(keys.appleClientId),
                  redirectURI: redirectURIApple,
                  scope: 'email name',
                  state: 'state',
                  nonce: 'nonce',
                  usePopup: true
                }}
                className='apple-auth-btn'
                buttonExtraChildren='Continuer avec Apple'
                onSuccess={(response) => {
                  const appleData = jwt_decode(response.authorization.id_token);
                  const { email } = appleData;
                  const receiverId = props.receiverId;
                  props.onFeedbackEnrollment({ email, undefined, receiverId });
                }}
                onError={(error) => {
                  console.log('Apple failure');
                  console.log(error);
                }} />
            </div>
          </div>
        </div>

        <div className='floatingContainer'>
          <img
            className='floating'
            alt='Man floating with phone'
            src={require('../../assets/images/floating.png')} />
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    receiverId: state.feedback.answers.user_id,
    error: state.user.error,
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    onFeedbackEnrollment: (enrollmentInfo) => dispatch(feedbackEnrollment(enrollmentInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);