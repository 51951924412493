import React from 'react';

export default props => {
  const generateInputs = () => {
    let inputs = [];
    for (let i = 1; i <= 5; i += 1) {
      let classes = ['scaleInputBtn'];

      inputs.push(
        <div key={`scaleBtn${i}`} className={classes.join(' ')} onClick={() => props.changed(i)} >
          <div className='outerInput'>
            { props.value === i ? <div className='innerInput'></div> : null }
          </div>
        </div>,
        (i !== 5 && 
          <div key={`dividerLine${i}`} className='dividerLine'></div>
        )
      )
    }
    return inputs;
  }
  return (
    <div className='scaleInputContainer'>
      <div className='scaleInput'>
        { generateInputs() }
      </div>
      <div className='radioLegend'>
        <p className='smallB'>Pas d'accord</p>
        <p className='smallB'>D'accord</p>
        <p className='smallB'>Totalement d'accord</p>
      </div>
    </div>
  );
}