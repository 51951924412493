import API from '../../utils/api';

import { history } from '../store';
import * as ActionTypes from './actionTypes';
import * as actions from './index';

const loadingStart = () => ({
    type: ActionTypes.LOADING_START
});

const loadingDone = () => ({
    type: ActionTypes.LOADING_DONE
});

const setErrorMessage = errorMsg => {
    return {
        type: ActionTypes.SET_ERROR_MSG,
        payload: errorMsg
    }
}

const moveSlides = slidesToMove => {
    return {
        type: ActionTypes.NAVIGATE,
        payload: slidesToMove
    }
}

export const navigate = slidesToMove => {
    return dispatch => {
        let number = 1;
        if (typeof slidesToMove === 'number') {
            number = slidesToMove;
        }
        dispatch(moveSlides(number));
    }
}

const verifSuccess = (receiverName, link) => {
    return {
        type: ActionTypes.VERIF_LINK_SUCCESS,
        payload: {
            receiverName,
            link
        }
    }
}

const questionsSuccess = (questions) => {
    return {
        type: ActionTypes.GET_QUESTIONS_SUCCESS,
        payload: [...questions]
    }
}

const getQuestions = () => {
    return async dispatch => {
        try {
            const questionsResult = await API.get('/questions/feedback');
            if (questionsResult.data) {
                dispatch(questionsSuccess(questionsResult.data.questions));
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export const verifyLink = (link, successCallback) => {
    return async dispatch => {
        try {
            const verifResult = await API.get('/feedback/' + link);
            // debugger
            if (verifResult.data.first_name) {
                localStorage.setItem('link_url_user', link);
                API.defaults.headers.common['Feedback'] = link;
                dispatch(verifSuccess(verifResult.data.first_name, link));
                dispatch(getQuestions());
                successCallback();
            } else throw new Error('Pas de prénom');
        } catch (e) {
            console.log(e);
            history.push('/invalid-link')
        }
    }
}


const updateStart = (answerValue, fieldName) => {
    return {
        type: ActionTypes.UPDATE_ANSWER_START,
        payload: {
            answerValue,
            fieldName
        }
    }
}

const updateFeedbackSuccess = (feedback) => {
    return {
        type: ActionTypes.UPDATE_ANSWER_SUCCESS,
        payload: {
            ...feedback
        }
    }
}

const updateCommentSuccess = (comment) => {
    return {
        type: ActionTypes.UPDATE_COMMENT_SUCCESS,
        payload: {
            ...comment
        }
    }
}

const updateFailed = () => {
    return {
        type: ActionTypes.UPDATE_ANSWER_FAILED
    }
}


export const updateAnswer = (answerValue, questionObj, isComment) => {
    return async dispatch => {
        try {
            let body = {};
            body[questionObj.field] = answerValue;
            if (isComment) {
                const commentResult = await API.put('/feedback/comment', {
                    [questionObj.field]: answerValue
                });
                dispatch(updateCommentSuccess(commentResult.data.comment))
            } else {
                dispatch(updateStart(answerValue, questionObj.field));
                const feedbackRes = await API.put('/feedback', {
                    ...body
                });
                dispatch(updateFeedbackSuccess(feedbackRes.data.feedback));
            }
        } catch (e) {
            console.log(e)
            dispatch(updateFailed());
        }
    }
}

export const updateQualities = (qualitiesObj) => {
    return async dispatch => {
        try {
            const updateRes = await API.put('/feedback', {
                ...qualitiesObj
            });
            dispatch(updateFeedbackSuccess(updateRes.data.feedback));
        } catch (e) {
            console.log(e)
            dispatch(updateFailed());
        }
    }
}

const getFeedbackFailed = () => {
    return {
        type: ActionTypes.GET_FB_FAILED
    }
}

const getFeedbackSuccess = (feedback, comment) => {
    return {
        type: ActionTypes.GET_FB_SUCCESS,
        payload: {
            feedback,
            comment
        }
    }
}

const getErrorMessage = _error => {
    debugger
    const {
        data,
        status
    } = _error;
    if (+status === 422 && data.errors) return data.errors[0].msg;
    if (data && data.msg) return data.msg;
    return 'Erreur inconnue, veuillez recommencer';
}

export const createFeedback = user => {
    return async dispatch => {
        try {
            dispatch(loadingStart());
            const creationResult = await API.post('/feedback', {
                userId: user.id
            });
            const feedback = creationResult.data.feedback;
            if (feedback.is_validated === true) {
                history.push('/already-sent');
            } else {
                history.push('/feedback');
            }

            feedback.giverFeedbackCount = creationResult.data.giverFeedbackCount;
            dispatch(getFeedbackSuccess({
                ...feedback
            }, creationResult.data.comment));
            dispatch(loadingDone());
        } catch (e) {
            console.log(e)
            dispatch(getFeedbackFailed());
            dispatch(setErrorMessage(e.response ? getErrorMessage(e.response) : e));
            dispatch(loadingDone());
        }

    }
}

export const feedbackEnrollment = (enrollmentInfo) => {
    return async dispatch => {
        try {
            dispatch(loadingStart());
            const enrollmentResult = await API.post('/feedbackEnrollment', {
                email: enrollmentInfo.email,
                password: enrollmentInfo.password,
                wants_newsletter: false
            });
            API.defaults.headers.common['Authorization'] = enrollmentResult.data.token;
            localStorage.setItem('link_url', enrollmentResult.data.user.link_url);
            const userFbCountResult = await API.post('/user/feedbackRequested');
            if (userFbCountResult.status !== 200) {
                throw new Error('Response status unvalid : ' + userFbCountResult.data.msg);
            }
            dispatch(actions.sendRequest({ receiverId: enrollmentInfo.receiverId, retour: true }));
            dispatch(loadingDone());
        } catch (e) {
            console.log(e.response);
            dispatch(setErrorMessage(e.response ? getErrorMessage(e.response) : e));
            dispatch(loadingDone());
        }

    }
}

const validationFailed = (error) => {
    return {
        type: ActionTypes.VALIDATION_FAILED,
        payload: error
    }
}

export const validate = (feedbackCount) => {
    return async dispatch => {
        try {
            const validationResult = await API.get('/feedback/validate');
            if (validationResult.data.success !== true) {
                throw new Error('Validation failed');
            }

            if (feedbackCount) {
                history.push('/see-you-soon');
            } else {
                history.push('/merci');
            }
        } catch (e) {
            console.log(e);
            dispatch(validationFailed(e));
        }
    }
}