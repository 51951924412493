import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCurrentWidth } from '../../../utils/resize';

import * as actions from '../../../store/actions';

import Aux from '../../../hoc/auxiliary';
import ScaleInput from './scaleInput';
import './scaleQuestion.scss';

const ScaleQuestion = (props) => {
  const { t } = useTranslation();
  let width = useCurrentWidth();

  const [curValue, setCurValue] = useState(0);
  const [comment, setComment] = useState('');
  const [enableClick, setEnableClick] = useState(true);
  const dbAnswer = props.dbAnswers[props.data.field];
  const dbComment = props.dbComments[props.data.field];
  useEffect(() => {
    if (props.dbAnswers) {
      const isAnswerDefined = typeof dbAnswer === 'number';
      if (isAnswerDefined) {
        return setCurValue(dbAnswer);
      }
    }
    setCurValue(0);
    // eslint-disable-next-line
  }, [dbAnswer, props.data.field]);
  
  useEffect(() => {
    if (props.dbComments) {
      const isCommentDefined = typeof props.dbComments[props.data.field] === 'string';
      if (isCommentDefined) {
        setComment(props.dbComments[props.data.field]);
        return; 
      }
    }
    setComment('');
    // eslint-disable-next-line
  }, [dbComment, props.data.field]);

  const handleSelectSubmit = (_value) => {
    if (enableClick) {
      setEnableClick(false);
      setCurValue(_value);
      let submitValue = _value;
      const isAnswerDefined = typeof props.dbAnswers[props.data.field] === 'number';
      if (!isAnswerDefined || submitValue !== props.dbAnswers[props.data.field]) {
        props.onSubmitAnswer(submitValue, {...props.data});
      }
      const isCommentDefined = typeof props.dbComments[props.data.field] === 'string';
      if (comment !== '' && (!isCommentDefined || comment !== props.dbComments[props.data.field])) {
        props.onSubmitComment(comment, {...props.data});
      }
  
      setTimeout(() => {
        window.scrollTo(0, 0);
        setCurValue(null);
        setEnableClick(true);
        props.navigate();
      }, 300);      
    }
  }

  const regex = /_NAME/gi;
  return (
    <Aux>
      <div className='scale-container'>
        <div className='slideBodyScale'>
          <h2 className='web'>
            {t('common.soft-skills.' + props.data.field)}
          </h2>

          <p className={width >= 416 ? null : 'smallB'}>
            {props.data.label.replace(regex, props.receiverName)}
          </p>
        </div>

        <div className='scaleInputQuestionContainer'>
          <ScaleInput
            value={curValue} 
            changed={ newValue => handleSelectSubmit(newValue) } />
        </div>
      </div>
    </Aux>
  )
}

const mapStateToProps = state => {
  return {
    dbAnswers: state.feedback.answers,
    dbComments: state.feedback.comments,
    receiverName: state.feedback.receiverName
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    onSubmitAnswer: (answerValue, question) => dispatch(actions.updateAnswer(answerValue, question, false)),
    onSubmitComment: (commentValue, question) => dispatch(actions.updateAnswer(commentValue, question, true))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScaleQuestion);