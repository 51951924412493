import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../store/store';
import * as actions from '../../store/actions';
import Question from '../../components/question/question';
import Layout from '../../hoc/Layout/Layout';
import './QuestionsTrack.css';

const qualitiesArray = ["implicated", "listening", "proactive", "curious", "autonomous", "organised", "empathetic", "flexible", 
    "team_spirit", "facilitating", "pragmatic", "self_control", "creative", "enthousiastic", "communicating", "bold"
];

const QuestionsTrack = (props) => {
    let body = null;
    
    const navigateHandler = (slidesToMove = 1) => {
        return props.navigate(slidesToMove);
    }

    let lastPage = -1;
    if (props.questions[props.questionIndex]){
        let tempQualities = [];
        let tempImprovements = [];

        // If the user is in the improvements page or comment page
        if (props.questionIndex === 21 || props.questionIndex === 22) {
            for (let key of qualitiesArray) {
                if (typeof props.dbAnswers[key] === 'number') {
                    if (props.dbAnswers[key] >= 4) {
                        tempQualities.push(key);
                    }
                    if (props.dbAnswers[key] === 1 || props.dbAnswers[key] === 2) {
                        tempImprovements.push(key);
                    }
                }
            }

            // If the user is in the improvements page
            if (props.questionIndex === 21) {
                // Update the back count page to -2 to come back to the last soft skill page
                if (tempQualities.length === 0) {
                    lastPage = -2;
                }
            } else {
                // If the user is in the comment page
                // And if there is neither qualities nor improvements
                if (tempQualities.length === 0 && tempImprovements.length === 0) {
                    // Update the back count page to -3 to come back to the last soft skill page
                    lastPage = -3;
                } else if (tempQualities.length > 0 && tempImprovements.length === 0) {
                    // And if there is qualities but not improvements
                    // Update the back count page to -2 to come back to the qualities page
                    lastPage = -2;
                }
            }
        }

        body = (
            <Question 
                data = {props.questions[props.questionIndex]}
                navigate={(slidesNb) => navigateHandler(slidesNb)}
            />
        );
    }
    let progress = 0;
    const isProgressNumeric = (typeof props.questionIndex === 'number' && props.questionIndex >= 0 && props.questions.length > 0);
    if(isProgressNumeric){
        // Add + 2 to include the 2 slides before questions in the progress
        progress = Math.round((props.questionIndex + 2) / (props.questions.length +2) * 100);
    }
    return (
        <Layout 
            back={props.questionIndex === 0 ? () => history.push('/auth') : (stepSize = lastPage) => navigateHandler(stepSize)} 
            isBackAllowed={true} 
            progress={progress}
        >
            {body}
        </Layout>
    );
};

const mapStateToProps = state => {
    return {
        questions: state.feedback.questions,
        dbAnswers: state.feedback.answers,
        questionIndex: state.feedback.curQuestion
    };
}

const mapDispatchToProps = dispatch => {
    return  {
        navigate: (slidesToMove=1) => dispatch(actions.navigate(slidesToMove)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsTrack);