import React, { useState } from 'react';
import { connect } from 'react-redux';

import { history } from '../../store/store';
import Button from '../../components/UI/button/button';
import HelpModal from '../../components/helpModal/helpModal';
import Layout from '../../hoc/Layout/Layout';
import Aux from '../../hoc/auxiliary';
import './Intro.scss';

const Intro = (props) => {
  const slides = [
    {
      "text": "Tu es ici pour donner ton avis sur ",
      "textTwo": "Ce feedback va lui permettre de mieux se connaitre en comparant le regard des autres avec son propre regard.",
      "title": "Hello, bienvenue sur Humoon !",
      "help": true,
      "btnText": "C’est parti !",
      "noBack": true
    }
  ];
  
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = slides[currentSlide];
  const slidesNb = slides.length;
  // Helper function to handle "Next" button click
  const handleNextClicked = () => {
    if (currentSlide >= (slidesNb -1)) {
      return history.push('/already-member');
    }
    setCurrentSlide(curSlide => (curSlide + 1));
  }
  // Intro component

  localStorage.setItem('name', '');
  localStorage.setItem('last_name', '');

  return (
    <Aux>
      <HelpModal receiverName={props.receiverName} show={isHelpModalOpen} modalClosed={() => setIsHelpModalOpen(false)} />
      <Layout
        isBackAllowed={data.isBackAllowed}
        back={() => setCurrentSlide(cur => cur -1)}>
        <div className='intro-container'>
          <div className='slideBodyIntro'>
            {data.title ? <h1>{data.title}</h1> : null}

            <div className='smallRocketShipContainer'>
              <img
                className='rocketShip'
                alt='Rocket ship'
                src={require('../../assets/images/smallRocketShip.png')} />
            </div>

            <p className='welcomeText'>
              {data.text}
              <span>{props.receiverName}.</span>
              <br/>
              {data.textTwo}
            </p>

            <div className='cta-dialog-container'>
              <div className={data.noBack  ? 'btnContainer noBack' : 'btnContainer' }>
                <Button clicked={handleNextClicked}>
                  {data.btnText}
                </Button>
              </div>
              {data.help ? <p className='link' onClick={() => setIsHelpModalOpen(true)}>En savoir + sur Humoon</p> : null}
            </div>
          </div>

          <div className='rocketShipContainer'>
            <img
              className='rocketShip'
              alt='Rocket ship'
              src={require('../../assets/images/rocketShip.png')} />
          </div>
        </div>
      </Layout>
    </Aux>
  );
}

const mapStateToProps = state => {
  return {
    receiverName: state.feedback.receiverName
  }
}

export default connect(mapStateToProps)(Intro);