import API from '../../utils/api';
import * as ActionTypes from './actionTypes';
import * as actions from './index';
import {history} from '../store';

const authStart = () => {
    return {
        type: ActionTypes.AUTH_START
    }
}

const authSuccess = (userData) => {
    return {
        type: ActionTypes.AUTH_SUCCESS,
        payload: userData
    }
}

const authFailed = (error) => {
    return {
        type: ActionTypes.AUTH_FAILED,
        payload: error
    }
}

const loadingStart = () => ({
    type: ActionTypes.LOADING_START
});

const loadingDone = () => ({
    type: ActionTypes.LOADING_DONE
});

export const setIsGoogleLogin = () => ({
    type: ActionTypes.SET_IS_GOOGLE_LOGIN
});

const setSuccessMessage = msg => {
    return {
        type: ActionTypes.SET_SUCCESS_MSG,
        payload: msg
    }
}

const setErrorMessage = errorMsg => {
    return {
        type: ActionTypes.SET_ERROR_MSG,
        payload: errorMsg
    }
}

const getErrorMessage = _error => {
    debugger
    const {data, status} = _error;
    if (+status === 422 && data.errors) return data.errors[0].msg;
    if (data.msg) return data.msg;
    return 'Erreur inconnue, veuillez recommencer';
}

export const signup = (userInfo) => {
    return async dispatch => {
        dispatch(authStart());
        try {
            const signupResult = await API.post('/feedbackSignup', {...userInfo});
            if (signupResult.status !== 201 && signupResult.status !== 200) {
                throw new Error('Response status invalid : ' + signupResult.status);
            }
            API.defaults.headers.common['Authorization'] = signupResult.data.token;
            dispatch(authSuccess(signupResult.data.user));
            localStorage.setItem('link_url', signupResult.data.user.link_url);
            dispatch(actions.createFeedback(signupResult.data.user));
        } catch (e) {
            console.log(e.response);
            dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
        }
    }
}

export const sendRequest = (requestInfo) => {
    return async dispatch => {
        try {
            dispatch(loadingStart());
            const requestResult = await API.post('/user/sendRequest', {...requestInfo});
            if (requestResult.status === 200 || requestResult.status === 204) {
                // HUM-129 : si résultat == 204, aucun feedback retour request a été envoyée (car feedback retour existant,
                // on ignore comme si ça a fonctionné)
                history.push('/bravo');
            }
            dispatch(loadingDone());
        } catch (e) {
            console.log(e);
            dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
            dispatch(loadingDone());
        }
    }
}

export const login = (userInfo) => {
    return async dispatch => {
        dispatch(authStart());
        try {
            const loginResult = await API.post('/login', {...userInfo});
            API.defaults.headers.common['Authorization'] = loginResult.data.token;
            dispatch(authSuccess(loginResult.data.user));
            localStorage.setItem('link_url', loginResult.data.user.link_url);
            dispatch(actions.createFeedback(loginResult.data.user));
        } catch (e) {
            console.log(e);
            dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
        }
    }
}

export const loginWithGoogle = (googleResponse) => {
    return async dispatch => {
        dispatch(authStart());
        try {
            const loginResponse = await API.post('/googleLogin', {...googleResponse.profileObj});
            dispatch(authSuccess(loginResponse.data.user));
            API.defaults.headers.common['Authorization'] = loginResponse.data.token;
            dispatch(setIsGoogleLogin());
            dispatch(actions.createFeedback(loginResponse.data.user));
        } catch (e) {
            console.log(e);
            dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
        }
    }
}

export const loginWithApple = (appleResponse) => {
    return async dispatch => {
        dispatch(authStart());
        try {
            const loginResponse = await API.post('/appleLogin', {
                identityToken: appleResponse.authorization.id_token
            });
            dispatch(authSuccess(loginResponse.data.user));
            API.defaults.headers.common['Authorization'] = loginResponse.data.token;
            dispatch(actions.createFeedback(loginResponse.data.user));
        } catch (e) {
            console.log(e);
            dispatch(authFailed(e.response ? getErrorMessage(e.response) : e));
        }
    }
}

export const sendResetPasswordEmail = (email) => {
    return async dispatch => {
        try {
            const sendResetResult = await API.post('/user/passwordEmail', {...email});
            if (sendResetResult.status === 200) {
                dispatch(setSuccessMessage('Email de réinitialisation envoyé'));
            }
        } catch (e) {
            dispatch(setErrorMessage(e.response ? getErrorMessage(e.response) : e));
        }
    }
}