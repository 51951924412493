import React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';

import * as actions from '../../../store/actions';
import Button from '../../UI/button/button';
import Aux from '../../../hoc/auxiliary';
import './3qualities.scss';

const qualitiesTextOne = 'Voici, d\'après toi, les points forts de _NAME.';
const qualitiesTextTwo = ' ceux sur lesquels tu considères que _NAME est meilleur(e) que les autres ?';

const qualitiesArray = ['implicated', 'listening', 'proactive', 'curious', 'autonomous', 'organised', 'empathetic', 'flexible', 
  'team_spirit', 'facilitating', 'pragmatic', 'self_control', 'creative', 'enthousiastic', 'communicating', 'bold'
];

const dbAnswersFields = ['quality1', 'quality2', 'quality3'];

class Qualities extends React.Component {
  state = {
    isInit: false,
    qualities: []
  }

  initializeQualities() {
    window.scrollTo(0, 0);
    const newQualities = [];
    for (let field of dbAnswersFields) {
      if (typeof this.props.dbAnswers[field] === 'string') {
        newQualities.push(this.props.dbAnswers[field]);
      }
    }
    if (newQualities.length > 0) {
      const newState = { ...this.state, qualities: [...newQualities], isInit: true };
      this.setState({ ...newState });
    }

    // Ignore this page if the user has not rated any quality above 3
    let tempQualities = [];
    for (let key of qualitiesArray) {
        if (typeof this.props.dbAnswers[key] === 'number' && this.props.dbAnswers[key] >= 4) {
          tempQualities.push(key);
        }
    }
    if (tempQualities.length === 0) {
      this.props.navigate();
    }
  }

  componentDidMount() {
    this.initializeQualities();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.dbAnswers !== this.props.dbAnswers) {
      this.initializeQualities();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.dbAnswers !== this.props.dbAnswers) {
      return true;
    }

    if (nextState.qualities !== this.state.qualities) {
      return true;
    }
    return false;
  }
    
  submitHandler() {
    let unknownAnswers = [];
    let knownValues = [];

    // Séparer les valeurs connues en base et les inconnues
    for (let answerField of dbAnswersFields) {
      if (this.props.dbAnswers[answerField] && this.state.qualities.includes(this.props.dbAnswers[answerField])) {
        knownValues.push(this.props.dbAnswers[answerField]);
      } else {
        unknownAnswers.push(answerField);
      }
    }

    // L'objet ou stocker les champs à updater
    let fieldsToUpdate = {};
    let needsUpdate = false;
    let count = 0;
    
    if (this.state.qualities.length > 0) {
      // Parmi les valeurs retenues
      for (let quality of this.state.qualities) {
        // Si elle n'est pas connue en base, l'ajouter aux champs à mettre à jour
        if (!knownValues.includes(quality)) {
          const dbField = unknownAnswers.pop();
          fieldsToUpdate[dbField] = quality;
          needsUpdate = true;
        }
      }
      // Check if some of the unknownAnswers already exist in dbAnswers
      for (let flied of unknownAnswers) {
        // If so, set to null
        if (this.props.dbAnswers[flied]) {
          count ++;
          fieldsToUpdate[flied] = null;
        }

        if (count > 0) {
          needsUpdate = true;
        }
      }
    } else {
      // If the user had already selected the qualities but later deselects them all, then
      // reset to null all qualities 
      for (let flied of unknownAnswers) {
        fieldsToUpdate[flied] = null;
        if (this.props.dbAnswers[flied]) {
            count ++;
        }
      }

      if (count > 0) {
        needsUpdate = true;
      }
    }

    if (needsUpdate) {
      this.props.onSubmitAnswer(fieldsToUpdate);
    }

    this.props.navigate();
  }

  selectHandler(_quality) {
    if (this.state.qualities.includes(_quality)) {
      const newQualities = [...this.state.qualities].filter(_elem => _elem !== _quality);
      this.setState({ ...this.state, qualities: [...newQualities] });
      return;
    } 
    if (this.state.qualities.length < 3) {
      const newQualities = [...this.state.qualities];
      newQualities.push(_quality);
      this.setState({ ...this.state, qualities: [...newQualities] });
    }
  }

  render() {
    const qualitiesBtns = qualitiesArray.map((elem, index) => {
      let classes = ['quality'];
      if (this.state.qualities.includes(elem)) {
        classes.push('activeQ');
      } else if (typeof this.props.dbAnswers[elem] === 'number' && this.props.dbAnswers[elem] >= 4) {
        // Check if the soft skill score is 4 or 5
        classes.push('highScore');
      }

      return (
        <div key={`quality#${index}`} className={classes.join(' ')} onClick={() => this.selectHandler(elem)} >
          <Translation>{ t => <p>{t('common.soft-skills.' + elem)}</p> }</Translation>
        </div>
      )
    });
    const regex = /_NAME/gi;
    return (
      <Aux>
        <div className='qualities-container'>
          <div className='slideBodyQualities'>
            <h2 className='web'>Points forts</h2>
            <p className='mb'>
              {qualitiesTextOne.replace(regex, this.props.receiverName)}
            </p>
            <p>
              Peux-tu sélectionner
              <span>{qualitiesTextTwo.replace(regex, this.props.receiverName)}</span>
              <br/>
              (entre 0 et 3 choix)
            </p>
          
            <div className='qualitiesSmallContainer'>
              {qualitiesBtns}
            </div>

            <div className='button-noQuality-container'>
              <div className='btnContainer'>
                <Button clicked={this.submitHandler.bind(this)}>
                  {this.state.qualities.length === 0 ? 'Passer' : 'Valider'}
                </Button>
              </div>
              {/* {this.state.qualities.length === 0 && (
                <p className='smallB noQuality'>Aucun point fort selectionné</p>
              )} */}
            </div>
          </div>
          
          <div className='qualitiesContainer'>
            {qualitiesBtns}
          </div>
        </div>
      </Aux>
    );
  }
}


const mapStateToProps = state => {
  return {
    dbAnswers: state.feedback.answers,
    receiverName: state.feedback.receiverName
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    onSubmitAnswer: (qualitiesObj) => dispatch(actions.updateQualities(qualitiesObj)),
    navigate: () => dispatch(actions.navigate(1))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Qualities);