import React from 'react';
import { connect } from 'react-redux';
import { useCurrentWidth } from '../../utils/resize';

import './AlreadySent.scss';

const AlreadySent = (props) => {
  localStorage.removeItem('link_url');
  localStorage.removeItem('link_url_user');
  localStorage.removeItem('isMember');
  localStorage.removeItem('name');
  localStorage.removeItem('last_name');

  return (
    <div className='alreadySendContainer'>
      <div className='slideBodyAlreadySend'>
        <div className='titleContainer'>
          <h4 className='web'>Feedback déjà envoyé</h4>
          <p>Merci {props.giverName}, tu as déjà donné un feedback à {props.receiverName}</p>
          <img
            className='check'
            alt='Check'
            src={require('../../assets/images/check.png')} />
        </div>

        <div className='motorbikeContainer'>
          <img
            className='motorbike'
            alt='Motorbike'
            src={useCurrentWidth() >= 416 ? require('../../assets/images/motorbike.png') : 
              require('../../assets/images/smallMotorbike.png')} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    receiverName: state.feedback.receiverName,
    giverName: state.user.first_name
  }
}

export default connect(mapStateToProps)(AlreadySent);
