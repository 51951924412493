import React from 'react';
import { useCurrentWidth } from '../../utils/resize';

import './Invalid.scss';

const Invalid = () => (
  <div className='invalidContainer'>
    <div className='slideBodyInvalid'>
      <div className='titleContainer'>
        <h2 className='web'>
          Houston, Houston, 
          <br/>
          on a un problème
        </h2>
        <p>Une erreur est survenue, veuillez nous excuser.</p>
      </div>

      <div className='personContainer'>
        <img
          className='personHidden'
          alt='Person hidden'
          src={useCurrentWidth() >= 416 ? require('../../assets/images/personHidden.png') : 
            require('../../assets/images/smallPersonHidden.png')} />
      </div>
    </div>
  </div>
)

export default Invalid;
