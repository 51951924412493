import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useCurrentWidth } from '../../utils/resize';

import { sendRequest } from '../../store/actions';
import Aux from '../../hoc/auxiliary';
import Layout from '../../hoc/Layout/Layout';
import FeedbackModal from '../../components/feedbackModal/feedbackModal';
import './Merci.scss';

const Merci = (props) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  window.scrollTo(0, 0);

  setTimeout( () => {
    setIsFeedbackModalOpen(true);
  }, 2000);

  return (
    <Aux>
      <FeedbackModal
        receiverName={props.receiverName}
        show={isFeedbackModalOpen}
        modalClosed={() => setIsFeedbackModalOpen(false)}
        onSendRequest={() => props.onSendRequest({ receiverId: props.receiverId, retour: true })} />

      <Layout>
        <div className='convert-container'>
          <div className='slideBodyConvert'>
            <div className='content'>
              <h2 className='web'>Merci {props.userName}</h2>
              <p className={useCurrentWidth() >= 416 ? null : 'smallB'}>
                Grâce à toi, {props.receiverName} va pouvoir découvrir ce que les autres pensent de lui et 
                <span> comparer cette vision avec la sienne.</span>
              </p>
              <div className='phoneSmallContainer'>
                <img
                  className='phone'
                  alt='Phone'
                  src={require('../../assets/images/smallPhone.png')} />
              </div>
            </div>
          </div>

          <div className='phoneContainer'>
            <img
              className='phone'
              alt='Phone'
              src={require('../../assets/images/phone.png')} />
          </div>
        </div>
      </Layout>
    </Aux>
  )
}

const mapStateToProps = state => {
  return {
    userName: state.user.first_name,
    receiverName: state.feedback.receiverName,
    receiverId: state.feedback.answers.user_id
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    onSendRequest: (requestInfo) => dispatch(sendRequest(requestInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Merci);