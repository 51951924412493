import * as ActionTypes from '../actions/actionTypes'

const { startSlides, endSlides } = require('../../config/standardSlides');


const sortQuestionsByOrder = (a, b) => {
  if(a.display_order < b.display_order){
      return 1;
  }
  return -1;
}


export const initialState = {
  isLoading: false,
  error: null,
  receiverName: null,
  questions: null,
  curQuestion: 0,
  answers: {},
  comments: {},
  unSyncedAnswers: {},
  link_url: null
};

const updateStart = (state, action) => {
  let newAnswers = {...state.answers};
  newAnswers[action.payload.fieldName] = action.payload.answerValue;
  let newUnSyncedAnswers = {...state.unSyncedAnswers};
  newUnSyncedAnswers[action.payload.fieldName] = action.payload.answerValue;
  return {
    ...state, 
    isLoading: true,
    answers: {...newAnswers},
    unSyncedAnswers: {...newUnSyncedAnswers},
  };
}

const updateSuccess = (state, action) => {
  let newAnswers = { ...state.answers };
  const fbData = { ...action.payload };
  const answerId = action.payload.id;
  let newUnSyncedAnswers = {...state.unSyncedAnswers};
  if(newUnSyncedAnswers.hasOwnProperty(answerId)){
    delete newUnSyncedAnswers[answerId];
    return {...state, unSyncedAnswers: {...newUnSyncedAnswers}, answers: { ...newAnswers, ...fbData }}
  }
  return { ...state, answers: { ...newAnswers, ...fbData }};
}

const updateCommentSuccess = (state, action) => {
  let newComments = { ...action.payload };
  return { ...state, comments: { ...newComments }};
}

const verifSuccess = (state, action) => {
  return {
    ...state, 
    isLoading: false,
    receiverName: action.payload.receiverName,
    link_url: action.payload.link
  }
}

const getFeedbackSuccess = (state, action) => {
  const newAnswers = { ...state.answers, ...action.payload.feedback };
  const newComments = { ...state.comments, ...action.payload.comment };
  return { ...state, answers: { ...newAnswers}, comments: { ...newComments }};
}

const getQuestionsSuccess = (state, action) => {
  const dbQuestions = [...action.payload];
  dbQuestions.sort(sortQuestionsByOrder);
  const newQuestions = [...startSlides, ...dbQuestions, ...endSlides];
  return {...state, questions: [...newQuestions]};
}

const navigate = (state, action) => {
  const curIndex = state.curQuestion;
  let newIndex = curIndex + action.payload;
  if(newIndex < 0){
    newIndex = 0;
  }
  if(typeof state.questions.length === 'number' && newIndex >= state.questions.length){
    newIndex = state.questions.length -1;
  }
  return {...state, curQuestion: newIndex};
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.NAVIGATE: return navigate(state, action);
    case ActionTypes.VERIF_LINK_SUCCESS: return verifSuccess(state, action);
    case ActionTypes.GET_QUESTIONS_SUCCESS: return getQuestionsSuccess(state, action);
    case ActionTypes.GET_FB_SUCCESS: return getFeedbackSuccess(state, action);
    case ActionTypes.GET_FB_FAILED: return {...state, error: action.payload};
    case ActionTypes.UPDATE_ANSWER_START: return updateStart(state, action);
    case ActionTypes.UPDATE_ANSWER_SUCCESS: return updateSuccess(state, action);
    case ActionTypes.UPDATE_COMMENT_SUCCESS: return updateCommentSuccess(state, action);
    case ActionTypes.UPDATE_ANSWER_FAILED: return {...state, error: action.payload};

    default:
      return state
  }
}
