import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { history } from './store/store';

import { defaultRoutes, authRoutes } from './routes/routes';
import { verifyLink } from './store/actions';

class App extends Component {
  componentDidMount(){
    const pathname = history.location.pathname.substring(1);
    const splitPathname = pathname.split('/');
    let successHistory = '/intro';
    let link = '';
    if (splitPathname.length > 1) {
      if (splitPathname[0] === 'request-feedbacks') {
        successHistory = '/request-feedbacks'
      }
      link = splitPathname[1];
    } else {
      // si le paramètre est de longueur 16 et que c'est une valeur hexadécimal -> c'est un link_url
      if (splitPathname[0] != null && /[0-9A-Fa-f]{14|15|16|17|18}/.test(splitPathname[0])) {
        link = splitPathname[0];
      } else if (localStorage.getItem('link_url_user')) {
        link = localStorage.getItem('link_url_user');
      } else {
        link = ''
      }
    }
    this.props.onVerifyLink(link, () => history.push(successHistory));
  }

  render(){
    return this.props.isAuthenticated ? authRoutes : defaultRoutes;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.id !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onVerifyLink: (link, successCallback) => dispatch(verifyLink(link, successCallback))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
