import React from 'react';

import { history } from '../../store/store';
import Button from '../../components/UI/button/button';
import Layout from '../../hoc/Layout/Layout';
import { useCurrentWidth } from '../../utils/resize';
import './AlreadyMember.scss';

const clickHandle = (isMember = false) => {
  localStorage.setItem('isMember', isMember);
  history.push('/auth');
}

const AlreadyMember = () => {
  let width = useCurrentWidth();
  window.scrollTo(0, 0);

  return (
    <Layout isBackAllowed={true} back={ () => history.push('/intro') }>
      <div className='slideBodyMember'>
        <div className='title-button'>
          <h2>As-tu déjà un compte Humoon ?</h2>
          
          <div className='btnContainer'>
            <Button btnType='tall' clicked={() => clickHandle(true)}>
              Oui
            </Button>
            <Button btnType='tall' clicked={() => clickHandle()}>
              Non
            </Button>
          </div>
        </div>

        <div className='memberContainer'>
          <img
            className='memberImg'
            alt='Member'
            src={width >= 416 ? require('../../assets/images/member.png') : 
              require('../../assets/images/smallMember.png')} />
        </div>
      </div>
    </Layout>
  );
}

export default AlreadyMember;