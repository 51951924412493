import React from 'react';

import { history } from '../../store/store';
import { useCurrentWidth } from '../../utils/resize';
import Layout from '../../hoc/Layout/Layout';
import Button from '../../components/UI/button/button';
import './NoProblem.scss';

const NoProblem = () => {
  let width = useCurrentWidth();
  window.scrollTo(0, 0);

  return (
    <Layout>
      <div className='slideBodyNoProblem'>
        <h2>C’est noté !</h2>
        <p>
          Pour demander d'autres feedbacks, utilise le lien que nous t'avons envoyé par email.
        </p>

        <div className='checkContainer'>
          <img
            className='todoList'
            alt='Todo list'
            src={width >= 416 ? require('../../assets/images/todoList.png') : 
              require('../../assets/images/smallTodoList.png')} />
        </div>

        <div className='btnContainer'>
          <Button clicked={() => history.push('/see-you-soon')}>
            Terminer
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default NoProblem;