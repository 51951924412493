import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useCurrentWidth } from '../../utils/resize';

import * as actions from '../../store/actions';

import Aux from '../../hoc/auxiliary';
import Transition from './transition/transition';
import Qualities from './3qualities/3qualities';
import Improvements from './3improvements/3improvements';
import ScaleQuestion from './scaleQuestion/scaleQuestion';
import Button from '../UI/button/button';
import TextareaAutosize from 'react-autosize-textarea';
import './question.scss';

const regex = /_NAME/gi;

let firstAccess = true;

const Question = (props) => {
  // Define states
  const [curValue, setCurValue] = useState(null);
  const [enableClick, setEnableClick] = useState(true);
  const [textInputValue, setTextInputValue] = useState('');
  let width = useCurrentWidth();
  let focusListenerDefined = false;

  useEffect(() => {
    window.scrollTo(0, 0);

    const ta = document.getElementById('commentSmallTextArea');
    if(ta != null && !focusListenerDefined) {
      ta.addEventListener('focus', () => {
        window.scrollTo({ top: ta.getBoundingClientRect().top - 40, behavior: 'smooth' });
      });

      focusListenerDefined = true;
    }

    if (props.data.type === 'OPTIONS_TEXT') {
      return;
    }
    // Initiate value
    let initialInputValue = null;
    let initialTextValue = '';
    if (props.data.type === 'TEXT') {
      initialInputValue = '';
    }
    if (props.dbAnswers[props.data.field] !== null) {
      initialInputValue = props.dbAnswers[props.data.field];
    }
    setCurValue(initialInputValue);
    setTextInputValue(initialTextValue);
  }, [props.data, props.dbAnswers]);

  useEffect(() => {
    if (props.data.type !== 'OPTIONS_TEXT') {
      return;
    }
    // Initiate value
    let initialInputValue = '';
    let initialTextValue = '';

    if (typeof props.dbAnswers[props.data.field] === 'string') {
      const dbValue = props.dbAnswers[props.data.field];
      let isValueStandard = false;
      const options = [...props.data.options];
      options.forEach(option => {
        if (option.value === dbValue) {
          initialInputValue = dbValue;
          isValueStandard = true;
          return;
        }
      });

      if (!isValueStandard) {
        initialInputValue ='$TEXT';
        initialTextValue = dbValue;
      }
    }
    setCurValue(initialInputValue);
    setTextInputValue(initialTextValue);
  }, [props.data, props.dbAnswers]);

  const handleSubmit = async (useTextInputValue = false, lastSlide = false) => {
    let submitValue = curValue;
    if (useTextInputValue === true) {
      submitValue = textInputValue;
    }
    if (submitValue !== null && submitValue !== '') {
      if (submitValue !== props.dbAnswers[props.data.field]) {
        props.onSubmitAnswer(submitValue, {...props.data});
      }
      
      if (lastSlide) {
        setCurValue('');
        setTextInputValue('');
        return props.validate(props.giverFeedbackCount);
      } else {
        let slidesToMove = 1;  
        if (props.data.skipNextIfFalse && curValue === false) {
          slidesToMove = props.data.skipNextIfFalse + 1;
        }  else if (props.data.skipNextIfTrue && curValue === true) {
          slidesToMove = props.data.skipNextIfTrue;
        }  
        setCurValue(null);
        setTextInputValue('');
        return props.navigate(slidesToMove);
      }
    }
    if (props.data.optional) {
      setTextInputValue('');
      if (lastSlide) {
        setCurValue('');
        return props.validate(props.giverFeedbackCount);
      } else {
        setCurValue(null);
        return props.navigate();
      }
    }
  }

  // QUESTION TYPES
  let textQuestion, optionsQuestion;
  if (props.data.type === 'TEXT') {
    textQuestion = () => {
      if(firstAccess) {
        window.scrollTo(0, 0);
        firstAccess = false;
      }

      return (
        <Aux>
          <div className='comment-container'>
            <div className='slideBodyComment'>
              <h2 className='web'>{props.data.label}</h2>
              <p className={width >= 416 ? null : 'smallB'}>
                <span>{props.data.description.replace(regex, props.receiverName)}</span>
                <br/>
                (Quelque chose que tu apprécies particulièrement, un conseil, un mot gentil ...)
              </p>

              <div className='disclaimerContainer'>
                {width >= 416 && (
                  <div className='disclaimer'>
                    <p className='tiny'>
                      Rassure-toi : tes réponses sont anonymes.
                      Ces informations servent seulement à prévenir {props.receiverName} que tu lui as donné un feedback.
                    </p>
                    <img
                      className='comment'
                      alt='Comment'
                      src={require('../../assets/images/comment.png')} />
                  </div>
                )}
                {width < 416 && (
                  <div className='disclaimer'>
                    <img
                      className='comment'
                      alt='Comment'
                      src={require('../../assets/images/commentSmall.png')} />
                    <p className='tiny'>
                      Rassure-toi : tes réponses sont anonymes.
                      Ces informations servent seulement à prévenir {props.receiverName} que tu lui as donné un feedback.
                    </p>
                  </div>
                )}
              </div>

              <TextareaAutosize
                className='commentSmallTextArea'
                id='commentSmallTextArea'
                placeholder={props.data.inputPlaceholder.replace(regex, props.receiverName)}
                value={curValue} onChange={(e) => setCurValue(e.target.value)} />

              <div className='btnContainer'>
                <Button clicked={() => handleSubmit(false, true)}
                  btnType={props.data.optional ? '' : (curValue === '' || !curValue) ? 'disabled' : ''}>
                  {props.btnText || 'Terminer'}
                </Button>
              </div>
            </div>

            <TextareaAutosize
              className='commentTextArea'
              placeholder={props.data.inputPlaceholder.replace(regex, props.receiverName)}
              value={curValue} onChange={(e) => setCurValue(e.target.value)} />
          </div>
        </Aux>
      );
    }
  }

  if (props.data.type === 'OPTIONS') {
    optionsQuestion = () => {
      const handleSelectSubmit = (_value) => {
        if (enableClick) {
          setEnableClick(false);
          setCurValue(_value);
          let submitValue = _value;
          if (submitValue !== props.dbAnswers[props.data.field]) {
            props.onSubmitAnswer(submitValue, {...props.data});
          }
          let slidesToMove = 1;  
          if (props.data.skipNextIfFalse && _value === false) {
            slidesToMove = props.data.skipNextIfFalse + 1;
          }  else if (props.data.skipNextIfTrue && _value === true) {
            slidesToMove = props.data.skipNextIfTrue;
          }  
  
          setTimeout( () => {
            window.scrollTo(0, 0);
            setCurValue(null);
            setEnableClick(true);
            setTextInputValue('');
            props.navigate(slidesToMove);
          }, 300);
        }
      }

      return (
        <div className='slideBodyOptions'>
          <h2>{props.data.label.replace(regex, props.receiverName)}</h2>
          {props.data.options.map((option, index) => {
            let classes = ['option'];
            if (curValue === option.value) {
              classes.push('activeOption');
            } 
            
            return (
              <div key={`option#${index}`} className={classes.join(' ')} onClick={() => handleSelectSubmit(option.value)} >
                <p>{option.label}</p>
              </div>
            );
          })}
        </div>
      );
    }
  }

  const transition = <Transition receiverName={props.receiverName} giverName={props.giverName} next={() => props.navigate(1)} /> ;
  const scaleQuestion = <ScaleQuestion data={props.data} navigate={() => props.navigate(1)} /> ;
  const qualities = <Qualities next={() => props.navigate(1)} /> ;
  const improvements = <Improvements next={() => props.navigate(1)} />

  const getQuestionBody = () => {
    switch(props.data.type) {
      case 'TEXT': return textQuestion();
      case 'OPTIONS': return optionsQuestion();
      case 'TRANSITION': return transition;
      case '3QUALITIES': return qualities;
      case '3IMPROVEMENTS': return improvements;
      default: return scaleQuestion;
    }
  }

  return getQuestionBody();
}

const mapStateToProps = state => {
  return {
    dbAnswers: state.feedback.answers,
    receiverName: state.feedback.receiverName,
    giverName: state.user.first_name,
    giverFeedbackCount: state.feedback.answers.giverFeedbackCount
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    onSubmitAnswer: (answerValue, question) => dispatch(actions.updateAnswer(answerValue, question)),
    validate: (feedbackCount) => dispatch(actions.validate(feedbackCount))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Question);