import React from 'react';
import { connect } from 'react-redux';
import { useCurrentWidth } from '../../utils/resize';

import Aux from '../auxiliary';
import Spinner from '../../components/UI/spinner/spinner';
import './Layout.scss';

const Layout = props => {
  let width = useCurrentWidth();
  let progress = typeof props.progress === 'number' ? props.progress : 0;

  const backArrow = (
    <div className='iconContainer arrowContainer'>
      <img
        onClick={() => props.back()}
        className='backArrow'
        alt='<-'
        src={require('../../assets/icons/left-arrow.svg')} />
    </div>
  );

  const closeCross = (
    <div className='iconContainer crossContainer'>
      <img
        onClick={() => props.closed()}
        className='closeCross'
        alt='x'
        src={require('./icons/close.svg')} />
    </div>
  );

  return (
    <Aux>
      {(window.location.pathname === '/auth' || window.location.pathname === '/enrollment') && width >= 416 && (
        <div className='half-bg'></div>
      )}
      <div className={window.location.pathname === '/intro' ? 'slideContainer blue-bg' : 
        (window.location.pathname === '/auth' || window.location.pathname === '/enrollment') && width >= 416 ? 'slideContainer halfBg' : 'slideContainer'}>
        <div className='logoContainer'>
          <img
            className='humoonLogo'
            alt='Humoon'
            src={require('../../assets/humoon.png')}
          />
        </div>
        {(((props.isBackAllowed || props.isCloseAllowed) && !props.isLoading) || window.location.pathname === '/feedback') && (
          <div className='arrow-progress-container'>
            {props.isBackAllowed && !props.isLoading ? backArrow : null}
            {props.isCloseAllowed && !props.isLoading ? closeCross : null}
            {window.location.pathname === '/feedback' && (
              <div className='progressBar'>
                <div className='progressValueOne' style={{ width: progress + '%' }}></div>
                <span>{ progress + '%' }</span>
                <div className='progressValueTwo' style={{ width: (100 - progress) + '%' }}></div>
              </div>
            )}
          </div>
        )}
        {props.isLoading ? <Spinner /> : props.children}
      </div>
    </Aux>
  );
}
const mapStateToProps = state => {
  return {
    isLoading: state.user.isLoading
  };
};

export default connect(mapStateToProps)(Layout);