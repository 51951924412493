import React, { useState, useEffect } from 'react';
import './Modal.scss';

const Modal = props => {
  const initialClasses = 'modal-container';
  const [classes, setClasses] = useState(initialClasses);

  useEffect(() => {
    if (props.show === true) {
      setClasses('modal-container modalOpen');
      return;
    }
    if (props.show === false && classes !== initialClasses) {
      setClasses('modal-container modalClosed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);
  
  return (
    <div className={classes}>
      <div className='modal' onClick={ () => props.modalClosed }>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
