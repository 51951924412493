import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Spinner from '../components/UI/spinner/spinner';
import Intro from '../views/Intro/Intro';
import AlreadyMember from '../views/AlreadyMember/AlreadyMember';
import Auth from '../views/Auth/Auth';
import QuestionsTrack from '../views/QuestionsTrack/QuestionsTrack';
import Merci from '../views/Merci/Merci';
import Invalid from '../views/Invalid/Invalid';
import AlreadySent from '../views/AlreadySent/AlreadySent';
import SeeYouSoon from '../views/SeeYouSoon/SeeYouSoon';
import Enrollment from '../views/Enrollment/Enrollment';
import Bravo from '../views/Bravo/Bravo';
import NoProblem from '../views/NoProblem/NoProblem';
import AskForFeedback from '../views/AskForFeedback/AskForFeedback';

export const defaultRoutes = (
  <Switch>
    <Route exact path="/already-member" component={AlreadyMember} />
    <Route exact path="/auth" component={Auth} />
    <Route exact path="/intro" component={Intro} />
    <Route exact path="/invalid-link" component={Invalid} />
    <Route exact path="/request-feedbacks" component={AskForFeedback} />
    <Route exact path="/see-you-soon" component={SeeYouSoon} />
    <Route path="/:id" component={Spinner} />
    <Redirect to='/invalid-link' />
  </Switch>
);

export const authRoutes = (
  <Switch>
    <Route exact path="/intro" component={Intro} />
    <Route exact path="/already-member" component={AlreadyMember} />
    <Route exact path="/feedback" component={QuestionsTrack} />
    <Route exact path="/auth" component={Auth} />
    <Route exact path="/see-you-soon" component={SeeYouSoon} />
    <Route exact path="/merci" component={Merci} />
    <Route exact path="/enrollment" component={Enrollment} />
    <Route exact path="/bravo" component={Bravo} />
    <Route exact path="/no-problem" component={NoProblem} />
    <Route exact path="/invalid-link" component={Invalid} />
    <Route exact path="/already-sent" component={AlreadySent} />
    <Route path="/:id" component={Spinner} />
    <Redirect to='/' />
  </Switch>
);

