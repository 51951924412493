import React from 'react';
import { connect } from 'react-redux';
import { Alert, Share } from 'react-native';
import API from '../../utils/api';
import keys from '../../config/keys';

import { history } from '../../store/store';
import Layout from '../../hoc/Layout/Layout';
import Button from '../../components/UI/button/button';
import './AskForFeedback.scss';

const AskForFeedback = (props) => {
  const feedbackRequested = async() => {
    try {
      const userFbCountResult = await API.post('/user/feedbackRequested/' + props.linkUrl);
      if (userFbCountResult.status !== 200) {
        throw new Error('Response status unvalid : ' + userFbCountResult.data.msg);
      }
    } catch (error) {
      Alert.alert(
        'Erreur lors de la demande de feedback',
        error.message,
        [{ text: 'OK', onPress: () => { } }]
      );
      return;
    }
  }

  const submitHandler = () => {
    sendMessage(props.receiverName, props.userLink);
    setTimeout(() => {
      feedbackRequested();
    }, 500);
  }

  const sendMessage = async(userName, userLink) => {
    try {
      await Share.share({
        title: '',
        message:
          `Hello, \n\n`+
          `Pour m'améliorer dans mon travail, je souhaite découvrir ou confirmer mes talents. `+
          `Pour cela je demande des feedbacks grâce à l'application Humoon. `+
          `Pourrais-tu prendre 5min top chrono pour me donner ton feedback : \n`+
          `${userLink} \n\n`+
          `PS : rassure toi, ton feedback est totalement anonyme, je ne verrai que le résultat global de tous les feedbacks reçus. \n\n`+
          `Merci beaucoup, \n`+
          `${userName}`
      });
    } catch (error) {
      Alert.alert(
        'Erreur lors de la demande de feedback',
        error.message,
        [{ text: 'OK', onPress: () => { } }]
      );
    }
  }

  return (
    <Layout>
      <div className='slideBodyAskFb'>
        <h2>Demander des feedbacks</h2>
        <p>Pour accéder aux services Humoon :</p>
        <div className='askFbListContainer'>
          <div className='listContent'>
            <div className='bulletList'></div>
            <p>Télécharge l’application et réalise ton auto-évaluation.</p>
          </div>
          <div className='listContent'>
            <div className='bulletList'></div>
            <p>Obtiens au moins 5 feedbacks (n’hésite pas à en demander plus).</p>
          </div>
        </div>

        <div className='imageContainer'>
          <div className='contentContainer'>
            <img
              className='rocketShip'
              alt='Rocket ship'
              src={require('../../assets/images/rocketShip.png')} />
            <p className='small'>
              <span>A qui demander un feedback ?</span>
              <br/>
              Si un jour tu devais organiser ton pot de départ, pense aux personnes que tu inviterais.
            </p>
          </div>
        </div>

        <div className='buttonsContainer'>
          <div className='feedbackBtn'>
            <Button clicked={() => submitHandler()}>
              Demander des feedbacks
            </Button>
          </div>
          <div className='laterBtn'>
            <Button btnType='flat' clicked={() => history.push('/see-you-soon', true)}>
              Non merci, pas pour l’instant
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    linkUrl: state.feedback.link_url,
    userLink: `${keys.feedbackUrl}/${state.feedback.link_url}`,
    receiverName: state.feedback.receiverName
  }
}

export default connect(mapStateToProps)(AskForFeedback);
