import React from 'react';

import Modal from '../UI/Modal/Modal';
import './helpModal.scss';

const modalText1 = `Le principe est simple : il te suffit de lui donner un feedback sur ses qualités comportementales au travail (aussi appelées « soft-skills »). Répondre à sa demande ne te prendra pas plus de 5 minutes (promis) !`;
const modalText2 = `Pssst : ce feedback sera plus utile pour _NAME si tu réponds en toute sincérité. Et bien entendu, tes réponses seront 100% anonymes. _NAME verra uniquement la synthèse de tous les feedbacks reçus qui sera disponible à partir de 5 évaluations.`;

const regex = /_NAME/gi;

export default props => (
  <Modal show={props.show} modalClosed={props.modalClosed} >
    <div className='helpModalContainer'>
      <img 
        alt='close' 
        src={require('../../assets/icons/close.svg')} 
        className='closeCross' 
        onClick={props.modalClosed} />
      <h4>
        Comment ça marche ?
      </h4>
      <p className='small'>
        { modalText1.replace(regex, props.receiverName) }
        <br/>
        <br/>
        { modalText2.replace(regex, props.receiverName) }
      </p>
    </div>
  </Modal>
)