export const startSlides = [
    {
        label: 'Quels sont vos liens professionnels ?',
        type: 'OPTIONS',
        field: 'prof_link',
        options: [
            {
                value: 1,
                label: "Je suis ou j'étais un collègue"
            },
            {
                value: 2,
                label: "Je suis ou j'étais son manager"
            },
            {
                value: 3,
                label: "Je suis ou j'étais son N-1"
            },
            {
                value: 4,
                label: "Autre"
            }
        ], 
    },
    {
        label: 'Quand avez-vous travaillé ensemble ?',
        type: 'OPTIONS',
        field: 'prof_period',
        options: [
            {
                value: 1,
                label: "On travaille actuellement ensemble"
            },
            {
                value: 2,
                label: "Il y a moins de 2 ans"
            },
            {
                value: 3,
                label: "Il y a moins de 5 ans"
            },
            {
                value: 4,
                label: "Il y a plus de 5 ans"
            }
        ], 
    },
    {
        label: 'Quelle est ou a été votre fréquence d’interaction ?',
        type: 'OPTIONS',
        field: 'prof_frequency',
        options: [
            {
                value: 1,
                label: "Tout le temps ou presque"
            },
            {
                value: 2,
                label: "Régulièrement"
            },
            {
                value: 3,
                label: "Ponctuellement"
            },
            {
                value: 4,
                label: "Plutôt rare"
            }
        ], 
    },
    {
        type: 'TRANSITION'
    }
];

export const endSlides = [
    {
        type: '3QUALITIES',
    },
    {
        type: '3IMPROVEMENTS',
    },
    {
        label: 'Pour terminer',
        optional: true,
        type: 'TEXT',
        description: 'Peux-tu laisser un mot à _NAME ?',
        field: 'note',
        inputPlaceholder: 'Ex: Ce que j’apprécie vraiment chez _NAME c’est son sens de l’humour. ' + 
            'Si j’avais un conseil à lui donner, ce serait de ranger son bureau.'
    }
];