import React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';

import * as actions from '../../../store/actions';
import Button from '../../UI/button/button';
import Aux from '../../../hoc/auxiliary';
import './3improvements.scss';

const improvementsTextOne = 'Voici, d\'après toi, les axes d\'amélioration de _NAME.';
const improvementsTextTwo = ' ceux sur lesquels tu considères que _NAME est moins bon(ne) que les autres ?';

const improvementsArray = ['implicated', 'listening', 'proactive', 'curious', 'autonomous', 'organised', 'empathetic', 'flexible', 
  'team_spirit', 'facilitating', 'pragmatic', 'self_control', 'creative', 'enthousiastic', 'communicating', 'bold'
];

const dbAnswersFields = ['improvement1', 'improvement2', 'improvement3'];

class Improvements extends React.Component {
  state = {
    isInit: false,
    improvements: []
  }

  initializeImprovements() {
    window.scrollTo(0, 0);
    const newImprovements = [];
    for (let field of dbAnswersFields) {
      if (typeof this.props.dbAnswers[field] === 'string') {
        newImprovements.push(this.props.dbAnswers[field]);
      }
    }
    if (newImprovements.length > 0) {
      const newState = { ...this.state, improvements: [...newImprovements], isInit: true };
      this.setState({ ...newState });
    }

    // Ignore this page if the user has not rated any quality less than 3
    let tempImprovements = [];
    for (let key of improvementsArray) {
      if (typeof this.props.dbAnswers[key] === 'number' && (this.props.dbAnswers[key] === 1 || this.props.dbAnswers[key] === 2)) {
        tempImprovements.push(key);
      }
    }
    if (tempImprovements.length === 0) {
      this.props.navigate();
    }
  }

  componentDidMount() {
    this.initializeImprovements();
  }
    
  componentDidUpdate(prevProps) {
    if (prevProps.dbAnswers !== this.props.dbAnswers) {
      this.initializeImprovements();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.dbAnswers !== this.props.dbAnswers) {
      return true;
    }

    if (nextState.improvements !== this.state.improvements) {
      return true;
    }
    return false;
  }
    
  submitHandler() {
    let unknownAnswers = [];
    let knownValues = [];

    // Séparer les valeurs connues en base et les inconnues
    for (let answerField of dbAnswersFields) {
      if (this.props.dbAnswers[answerField] && this.state.improvements.includes(this.props.dbAnswers[answerField])) {
        knownValues.push(this.props.dbAnswers[answerField]);
      } else {
        unknownAnswers.push(answerField);
      }
    }

    // L'objet ou stocker les champs à updater
    let fieldsToUpdate = {};
    let needsUpdate = false;
    let count = 0;

    if (this.state.improvements.length > 0) {
      // Parmi les valeurs retenues
      for (let improvement of this.state.improvements) {
        // Si elle n'est pas connue en base, l'ajouter aux champs à mettre à jour
        if (!knownValues.includes(improvement)) {
          const dbField = unknownAnswers.pop();
          fieldsToUpdate[dbField] = improvement;
          needsUpdate = true;
        }
      }
      // Check if some of the unknownAnswers already exist in dbAnswers
      for (let flied of unknownAnswers) {
        // If so, set to null
        if (this.props.dbAnswers[flied]) {
          count ++;
          fieldsToUpdate[flied] = null;
        }

        if (count > 0) {
          needsUpdate = true;
        }
      }
    } else {
      // If the user had already selected the improvements but later deselects them all, then
      // reset to null all improvements 
      for (let flied of unknownAnswers) {
        fieldsToUpdate[flied] = null;
        if (this.props.dbAnswers[flied]) {
          count ++;
        }
      }

      if (count > 0) {
        needsUpdate = true;
      }
    }
    
    if (needsUpdate) {
      this.props.onSubmitAnswer(fieldsToUpdate);
    }

    this.props.navigate();
  }

  selectHandler(_improvement) {
    if (this.state.improvements.includes(_improvement)) {
      const newImprovements = [...this.state.improvements].filter(_elem => _elem !== _improvement);
      this.setState({ ...this.state, improvements: [...newImprovements] });
      return;
    } 
    if (this.state.improvements.length < 3) {
      const newImprovements = [...this.state.improvements];
      newImprovements.push(_improvement);
      this.setState({ ...this.state, improvements: [...newImprovements] });
    }
  }

  render() {
    const improvementBtns = improvementsArray.map((elem, index) => {
      let classes = ['improvement'];
      if (this.state.improvements.includes(elem)) {
        classes.push('activeQ');
      } else if (typeof this.props.dbAnswers[elem] === 'number' && (this.props.dbAnswers[elem] === 1 || this.props.dbAnswers[elem] === 2)) {
          // Check if the soft skill score is 1 or 2
        classes.push('lowScore');
      }

      return (
        <div key={`improvement#${index}`} className={classes.join(' ')} onClick={() => this.selectHandler(elem)} >
          <Translation>{ t => <p>{t('common.soft-skills.' + elem)}</p> }</Translation>
        </div>
      )
    });
    const regex = /_NAME/gi;
    return (
      <Aux>
        <div className='improvements-container'>
          <div className='slideBodyImprovements'>
            <h2 className='web'>Axes d'amélioration</h2>
            <p className='mb'>
              {improvementsTextOne.replace(regex, this.props.receiverName)}
            </p>
            <p>
              Peux-tu sélectionner
              <span>{improvementsTextTwo.replace(regex, this.props.receiverName)}</span>
              <br/>
              (entre 0 et 3 choix)
            </p>

            <div className='improvementsSmallContainer'>
              {improvementBtns}
            </div>

            <div className='button-noImprovement-container'>
              <div className='btnContainer'>
                <Button clicked={this.submitHandler.bind(this)}>
                  {this.state.improvements.length === 0 ? 'Passer' : 'Valider'}
                </Button>
              </div>
              {/* {this.state.improvements.length === 0 && (
                <p className='smallB noImprovement'>Aucun axe d'amélioration selectionné</p>
              )} */}
            </div>
          </div>

          <div className='improvementsContainer'>
            {improvementBtns}
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    dbAnswers: state.feedback.answers,
    receiverName: state.feedback.receiverName
  }
}

const mapDispatchToProps = dispatch => {
  return  {
    onSubmitAnswer: (improvementsObj) => dispatch(actions.updateQualities(improvementsObj)),
    navigate: () => dispatch(actions.navigate(1))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Improvements);