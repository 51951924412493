import React, { useState, useEffect } from 'react';
import './smallModal.scss';

const SmallModal = props => {
  const initialClasses = 'smallModal-container';
  const [classes, setClasses] = useState(initialClasses);

  useEffect(() => {
    if (props.show === true) {
      setClasses('smallModal-container smallModalOpen');
      return;
    }
    if (props.show === false && classes !== initialClasses) {
      setClasses('smallModal-container smallModalClosed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);
  
  return (
    <div className={classes}>
      <div className='smallModal' onClick={ () => props.modalClosed }>
        {props.children}
      </div>
    </div>
  );
};

export default SmallModal;
