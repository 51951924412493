import React, {useState, useEffect} from 'react';
import {isDesktop} from 'react-device-detect';
import {connect} from 'react-redux';
import {GoogleLogin} from 'react-google-login';
import AppleSignin from 'react-apple-signin-auth';

import {history} from '../../store/store';
import * as ActionTypes from '../../store/actions/actionTypes';
import * as actions from '../../store/actions';
import Layout from '../../hoc/Layout/Layout';
import Aux from '../../hoc/auxiliary';
import SmallModal from '../../components/UI/smallModal/smallModal';
import Button from '../../components/UI/button/button';
import keys from '../../config/keys';
import './Auth.scss';

const setErrorMessage = errorMsg => {
    return {
        type: ActionTypes.SET_ERROR_MSG,
        payload: errorMsg
    }
}

let firstAccess = true;

const AuthModal = (props) => {
    if (firstAccess) {
        window.scrollTo(0, 0);
        firstAccess = false;
    }

    const redirectURIApple = window.location.origin + '/auth';
    const isMember = JSON.parse(localStorage.getItem('isMember'));
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
    const [error, setError] = useState(props.error || null);
    const [success, setSuccess] = useState(props.success || null);
    const [first_name, setFirstName] = useState(localStorage.getItem('name'));
    const [last_name, setLastName] = useState(localStorage.getItem('last_name'));
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isValid = (first_name !== '' && last_name !== '') || (email !== '' && password != null && password.length >= 8);
    const device = isDesktop ? 'DESKTOP' : 'MOBILE';
    const progress = (
        props.questions &&
        typeof props.questions.length === 'number' &&
        props.questions.length > 0
    ) ? 1 / (props.questions.length) * 100 : 0;

    const onSubmitHandler = () => {
        setError(null);
        if (isMember) {
            props.onLogin({email, password});
        } else {
            props.onSignup({first_name, last_name, device});
        }
    }

    const handlerForgotPassword = (sendEmail = false) => {
        setError(null);
        if (sendEmail) {
            props.onSendResetPasswordEmail({email});
            setIsHelpModalOpen(false);
        } else {
            if (email && email !== '') {
                setIsHelpModalOpen(true);
            } else {
                props.onSendError('Veuillez renseigner votre email');
            }
        }
    }

    const handleKeyDownCloseKeyboard = (event) => {
        if (event.key === 'Enter') {
            document.activeElement.blur();
        }
    }

    useEffect(() => setError(props.error), [props.error]);
    useEffect(() => setSuccess(props.success), [props.success]);
    useEffect(() => setError(null), []);



    return (
        <Aux>
            <SmallModal show={isHelpModalOpen} modalClosed={() => setIsHelpModalOpen(false)}>
                <div className='smallModalContainer'>
                    <p>
                        Nous allons vous envoyer un email pour réinitialiser votre mot de passe. Voulez vous continuer ?
                    </p>
                    <div className='buttonsContainer'>
                        <Button clicked={() => handlerForgotPassword(true)}>
                            Oui, continuer
                        </Button>
                        <div className='noBtn'>
                            <Button btnType='flat' clicked={() => setIsHelpModalOpen(false)}>
                                Non
                            </Button>
                        </div>
                    </div>
                </div>
            </SmallModal>

            <Layout isBackAllowed={true} progress={progress} back={() => history.push('/already-member')}>
                <div className='auth-container'>
                    <div className='slideBodyAuth'>
                        <div className='content'>
                            <h2>
                                {isMember ? 'Avant de commencer, peux-tu t’identifier ?' : 'Comment t’appelles-tu ?'}
                            </h2>

                            {isMember ? (
                                <form className='authForm' onSubmit={() => onSubmitHandler()}>
                                    <p>Saisis ton identifiant</p>
                                    <input type='text' onChange={e => setEmail(e.target.value)} value={email}
                                           onKeyDown={handleKeyDownCloseKeyboard}
                                           placeholder='elon@mail.com'/>
                                    <p>Saisis ton mot de passe</p>
                                    <input type='password' onChange={e => setPassword(e.target.value)} value={password}
                                           onKeyDown={handleKeyDownCloseKeyboard}
                                           placeholder='Mot de passe'/>
                                </form>
                            ) : (
                                <form className='authForm' onSubmit={() => onSubmitHandler()}>
                                    <p>Quel est ton prénom ?</p>
                                    <input className="input" type='text' onChange={e => {
                                        setFirstName(e.target.value);
                                        localStorage.setItem('name', e.target.value);
                                    }} onKeyDown={handleKeyDownCloseKeyboard} value={first_name} placeholder='Prénom'/>
                                    <p>Et ton nom ?</p>
                                    <input className="input" type='text' onChange={e => {
                                        setLastName(e.target.value);
                                        localStorage.setItem('last_name', e.target.value);
                                    }} onKeyDown={handleKeyDownCloseKeyboard} value={last_name} placeholder='Nom'/>
                                </form>
                            )}

                            {error && (
                                <p className='small authError'>{error}</p>
                            )}
                            {success && (
                                <p className='small mailSuccess'>{success}</p>
                            )}

                            {isMember && (
                                <p className='link' onClick={() => handlerForgotPassword()}>
                                    Mot de passe oublié ?
                                </p>
                            )}

                            {!isMember && (
                                <div className='disclaimerContainer'>
                                    <div className='disclaimer'>
                                        <img
                                            className='balanceSheet'
                                            alt='Balance sheet'
                                            src={require('../../assets/images/balanceSheet.svg')}/>
                                        <p className='tiny'>
                                            Rassure-toi : tes réponses sont anonymes.
                                            Ces informations servent seulement à prévenir {props.receiverName} que tu
                                            lui as donné un feedback.
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div className='btnContainer'>
                                <Button btnType={isValid ? '' : 'disabled'} clicked={() => onSubmitHandler()}>
                                    {isMember ? 'Valider' : 'Commencer'}
                                </Button>
                            </div>

                            {isMember && (
                                <div className='separator'>OU</div>
                            )}

                            {isMember && (
                                <div className='socialButtons'>
                                    <GoogleLogin
                                        clientId={keys.googleClientId}
                                        buttonText="Continuer avec Google"
                                        onSuccess={(response) => props.onGoogleLogin(response)}
                                        onFailure={(error) => {
                                            console.log('Google failure');
                                            console.log(error);
                                        }}
                                        cookiePolicy={'single_host_origin'}
                                        className='googleBtn'/>

                                    <AppleSignin
                                        authOptions={{
                                            clientId: (String)(keys.appleClientId),
                                            redirectURI: redirectURIApple,
                                            scope: 'email name',
                                            state: 'state',
                                            nonce: 'nonce',
                                            usePopup: true
                                        }}
                                        className='apple-auth-btn'
                                        buttonExtraChildren='Continuer avec Apple'
                                        onSuccess={(response) => props.onAppleLogin(response)}
                                        onError={(error) => {
                                            console.log('Apple failure');
                                            console.log(error);
                                        }}/>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='manPcContainer'>
                        <img
                            className='manPc'
                            alt='Man with pc'
                            src={require('../../assets/images/manPc.png')}/>
                    </div>
                </div>
            </Layout>
        </Aux>
    );
}

const mapStateToProps = state => {
    return {
        isAuthed: state.user.id !== null,
        error: state.user.error,
        success: state.user.success,
        questions: state.feedback.questions,
        receiverName: state.feedback.receiverName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAppleLogin: (appleResponse) => dispatch(actions.loginWithApple(appleResponse)),
        onGoogleLogin: (googleResponse) => dispatch(actions.loginWithGoogle(googleResponse)),
        onLogin: (userInfo) => dispatch(actions.login(userInfo)),
        onSignup: (userInfo) => dispatch(actions.signup(userInfo)),
        onSendResetPasswordEmail: (email) => dispatch(actions.sendResetPasswordEmail(email)),
        onSendError: (error) => dispatch(setErrorMessage(error))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);