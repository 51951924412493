import * as ActionTypes from '../actions/actionTypes'

export const initialState = {
  isLoading: false,
  error: null,
  success: null,
  id: null,
  isGoogleLogin: false
}

const authSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    ...action.payload
  }
}

const authFailed = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.payload
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOADING_START: return {...state, isLoading: true};
    case ActionTypes.LOADING_DONE: return {...state, isLoading: false};
    case ActionTypes.AUTH_START: return {...state, isLoading: true, error: null };
    case ActionTypes.AUTH_SUCCESS: return authSuccess(state,action);
    case ActionTypes.AUTH_FAILED: return authFailed(state,action);
    case ActionTypes.SET_ERROR_MSG: return {...state, error: action.payload};
    case ActionTypes.SET_SUCCESS_MSG: return {...state, success: action.payload};
    case ActionTypes.SET_IS_GOOGLE_LOGIN: return {...state, isGoogleLogin: true};

    default:
      return state
  }
}
