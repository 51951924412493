import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import feedbackReducer from './feedback';
import userReducer from './user';

const createRootReducer = history => combineReducers({
    router: connectRouter(history),
    user: userReducer,
    feedback: feedbackReducer
});

export default createRootReducer;