import React from 'react';

import { history } from '../../store/store';
import API from '../../utils/api';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/button/button';
import './feedbackModal.scss';

const modalText = ' donner un feedback à _NAME, ';
const regex = /_NAME/gi;

const FeedbackModal = (props) => {
  const isMember = JSON.parse(localStorage.getItem('isMember'));
  
  const submitHandler = async() => {
    try {
      if (isMember) {
        const userFbCountResult = await API.post('/user/feedbackRequested');
        if (userFbCountResult.status !== 200) {
          throw new Error('Response status unvalid : ' + userFbCountResult.data.msg);
        }

        props.onSendRequest();
      } else {
        history.push('/enrollment');
      }
    } catch (error) {
      console.log('Erreur lors de la demande de feedback', error.message);
      return;
    }
  }

  return (
    <Modal show={props.show} modalClosed={props.modalClosed}>
      <div className='feedbackModalContainer'>
        <img 
          alt='close' 
          src={require('../../assets/icons/close.svg')}
          className='closeCross' 
          onClick={props.modalClosed} />
        <p className='small'>
          Maintenant que tu as pris le temps de
          <span>{ modalText.replace(regex, props.receiverName) }</span>
          souhaiterais-tu qu’il te
          <span> donne un feedback à son tour ?</span>
        </p>
        <div className='buttonsContainer'>
          <Button clicked={() => submitHandler()}>
            Oui, demander mon feedback
          </Button>
          <div className='laterBtn'>
            <Button btnType='flat' clicked={() => history.push('/see-you-soon')}>
              Passer
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FeedbackModal;