export const NAVIGATE = 'NAVIGATE';
export const VALIDATION_FAILED = 'VALIDATION_FAILED';
export const VERIF_LINK_SUCCESS = 'VERIF_LINK_SUCCESS';

export const AUTH_START = 'AUTH_START';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const GET_FB_FAILED = 'GET_FB_FAILED';
export const GET_FB_SUCCESS = 'GET_FB_SUCCESS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';

export const GET_GENERAL_DATA_START = 'GET_GENERAL_DATA_START';
export const GET_GENERAL_DATA_FAILED = 'GET_GENERAL_DATA_FAILED';
export const GET_GENERAL_DATA_SUCCESS = 'GET_GENERAL_DATA_SUCCESS';

export const UPDATE_ANSWER_START = 'UPDATE_ANSWER_START';
export const UPDATE_ANSWER_FAILED = 'UPDATE_ANSWER_FAILED';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';

export const LOADING_START = 'LOADING_START';
export const LOADING_DONE = 'LOADING_DONE';
export const SET_ERROR_MSG = 'SET_ERROR_MSG';
export const SET_SUCCESS_MSG = 'SET_SUCCESS_MSG';

export const SET_IS_GOOGLE_LOGIN = 'SET_IS_GOOGLE_LOGIN';