import React from 'react';
import Button from '../../UI/button/button';
import Aux from '../../../hoc/auxiliary';
import { useCurrentWidth } from '../../../utils/resize';
import './transition.scss';

export default props => (
  <Aux>
    <div className='transition-container'>
      <div className='slideBodyTransition'>
        <h2 className='web'>Merci {props.giverName}</h2>

        <p className={useCurrentWidth() >= 416 ? null : 'smallB'}>
          <span>3 conseils importants</span> avant de donner ton feedback !
        </p>
        <div className='advicesContainer'>
          <p className={useCurrentWidth() >= 416 ? null : 'smallB'}>
            Le 1er avis est toujours le bon.<br/>
            <span>Sois spontané(e).</span>
          </p>
          <p className={useCurrentWidth() >= 416 ? null : 'smallB'}>
            Si tu ne sais pas quoi répondre.<br/>
            <span>Sois intuitif.</span>
          </p>
          <p className={useCurrentWidth() >= 416 ? null : 'smallB'}>
            Ton feedback est 100% anonyme.<br/>
            <span>Donc sois sincère.</span>
          </p>
        </div>

        <div className='smallOkContainer'>
          <img
            className='ok'
            alt='Ok'
            src={require('../../../assets/images/smallOk.png')} />
        </div>

        <div className='btnContainer'>
          <Button clicked={() => props.next()}>
            C'est parti
          </Button>
        </div>
      </div>

      <div className='okContainer'>
        <img
          className='ok'
          alt='Ok'
          src={require('../../../assets/images/ok.png')} />
      </div>
    </div>
  </Aux>
);
